$info: #27dbec;
$primary: #0f629b;
$font-family-sans-serif: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@import '../node_modules/bootstrap/scss/bootstrap';
@import "./bootstrap.css";

html {
  font-size: 93.75%;
}

.spacer {
  min-height: 70px;
}

.footer-spacer {
  color: white;
  min-height: 122px;

  @media (max-device-width: 767px) {
    height: 715px;
  }
}

.body {
  font-family: sans-serif;
  overflow-x: hidden;
  width: 100vw;
}

.align-center {
  text-align: center;
}

.absolute-center {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}

.fake-link {
  color: #0f629b;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    height: 0px;
    width: 0%;
    visibility: hidden;
    flex: none;
    position: absolute;
    z-index: -1000;
  }
}