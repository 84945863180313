.score-box {
  min-height: 100%;
  color: white;
  font-weight: bold;
  line-height: 45px;
  font-size: 17px;
  text-align: center;
}

.score-color-background {
  background: #eee;
  position: absolute;
  height: 10000px;
  width: 100%;
  top: -200px;
  left: 0;
}

.school-name {
  font-weight: bold;
  padding-left: 27px;
}

.college-cell {
  background: white;
  margin-bottom: 16px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 4px;
  overflow: hidden;
  padding: 4px 0;
  // margin-top: 24px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  height: 58px;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    max-width: 100% !important;
    margin-left: 0px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .remove-button {
    padding: 5px 10px;
    font-size: 24px;
    line-height: 21px;
    font-weight: bold;
  }

  .score-box {
    min-height: 100%;
    color: white;
    font-weight: bold;
    line-height: 45px;
    font-size: 17px;
    text-align: center;
  }

  .score-color-background {
    background: #eee;
    position: absolute;
    height: 10000px;
    width: 100%;
    top: -200px;
    left: 0;
  }

  .school-name {
    font-weight: bold;
    padding-left: 27px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.big-text {
  font-size: 64px;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }
}

.mobile-run-btn {
  visibility: hidden;
  height: 0px;

  @media screen and (max-width: 768px) {
    visibility: visible;
    height: unset;
    padding-top: 12px;
  }
}